import React, { useState, useEffect } from "react";
import { Cascader, Icon, Input } from "antd";
import provinces from "china-division/dist/provinces.json";
import cities from "china-division/dist/cities.json";
import areas from "china-division/dist/areas.json";

areas.forEach((area) => {
  const matchCity = cities.filter((city) => city.code === area.cityCode)[0];
  if (matchCity) {
    matchCity.children = matchCity.children || [];
    matchCity.children.push({
      label: area.name,
      value: area.code,
    });
  }
});

cities.forEach((city) => {
  const matchProvince = provinces.filter(
    (province) => province.code === city.provinceCode
  )[0];
  if (matchProvince) {
    matchProvince.children = matchProvince.children || [];
    matchProvince.children.push({
      label: city.name,
      value: city.code,
      children: city.children,
    });
  }
});

const options = provinces.map((province) => ({
  label: province.name,
  value: province.code,
  children: province.children,
}));

const getCity = (arr, code) => {
  let str = "";
  const reapet = (arrs, codes) => {
    arrs.forEach((i) => {
      if (i.value === codes[0]) {
        codes.shift();
        let { children } = i;
        str += i.label;
        return children ? reapet(children, codes, str) : str;
      }
    });
  };
  reapet(arr, code);
  return str;
};

const CityParser = (code) => {
  let provincesCode = code.substr(0, 2);
  let citiesCode = code.substr(2, 4);
  let areasCode = code.substr(6);
  let nstr = getCity(options, [provincesCode, citiesCode, areasCode]);
  return nstr;
};

class PassWord extends React.Component {
  componentDidMount() {
    if (this.props.autoComplete === "new-password") {
      setTimeout(() => {
        this.pass.type = "password";
      }, 1000);
    } else {
      this.pass.type = "password";
    }
  }

  render() {
    let open = true;
    let { placeholder, onChange, style, autoComplete, value } = this.props;
    return (
      <div className="pass_ipt_div">
        <input
          style={style}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          type="text"
          placeholder={placeholder}
          ref={(ipt) => (this.pass = ipt)}
          className="input_pass"
        />
        <i
          onClick={(e) => {
            open = !open;
            if (open) {
              e.target.className = "eys_close";
              this.pass.type = "password";
            } else {
              e.target.className = "eys_open";
              this.pass.type = "text";
            }
          }}
          className="eys_close"
        ></i>
      </div>
    );
  }
}

const AddressesMobileComponents = (props) => {
  let { onClose, defaultValue } = props;
  const [data, setData] = useState([]);
  const [step, setStep] = useState("provinces");
  useEffect(() => {
    if (defaultValue) {
      let arr = [];

      arr[0] = provinces.filter((i) => i.code === defaultValue.substr(0, 2))[0];

      arr[1] = cities.filter((i) => i.code === defaultValue.substr(2, 4))[0];

      arr[2] = areas.filter((i) => i.code === defaultValue.substr(6))[0];

      setData(arr);

      setStep("areas");
    }
  }, [defaultValue]);
  let nArr = data.length ? data : new Array(3).fill({});
  let arr = {
    provinces,
    cities: cities.filter((i = {}) => i.provinceCode === nArr[0].code),
    areas: areas.filter((i = {}) => i.cityCode === nArr[1].code),
  };
  let arrKey = ["provinces", "cities", "areas"];
  let Next = (code) => {
    return arrKey.indexOf(code);
  };
  return (
    <div className="mobile_addresses_box">
      <div className="flex_start select-address-header">
        {data.length
          ? data.map((i, index) => {
              return (
                <div
                  className={`${index === arrKey.indexOf(step) && "active"}`}
                  key={`${i.code}-${index}`}
                  onClick={() => {
                    if (index === 0) {
                      setData([
                        i,
                        {
                          name: "请选择",
                          provinceCode: i.code,
                          value: "cities",
                        },
                        {},
                      ]);
                    }
                    setStep(arrKey[index]);
                  }}
                >
                  {i.name}
                </div>
              );
            })
          : "请选择地区"}
      </div>
      <div className="mobile_addresses_content">
        {arr[step].map((i) => {
          return (
            <div
              className="flex_between"
              key={i.code}
              onClick={() => {
                let NextIndex = Next(step) + 1;
                nArr[NextIndex] = {
                  name: "请选择",
                  provinceCode: i.code,
                  value: "cities",
                };
                nArr[Next(step)] = { ...i, value: step };
                setData([...nArr]);
                if (NextIndex === 3) {
                  onClose(
                    nArr.reduce((prev, next) => {
                      return next.code ? (prev += next.code) : prev;
                    }, "")
                  );
                } else {
                  setStep(arrKey[Next(step) + 1]);
                }
              }}
            >
              <div>{i.name}</div>
              {nArr[Next(step)].code === i.code ? (
                <div>
                  <Icon type="check" />
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

class Inputlogin extends React.Component {
  render() {
    let {
      addonBefore,
      placeholder,
      onChange,
      style,
      defalutValue,
      autoComplete,
    } = this.props;
    return addonBefore ? (
      <div>
        <label>{addonBefore}</label>
        <label>
          <Input
            autoComplete="off"
            defaultValue={defalutValue}
            onChange={onChange}
            type="tel"
            style={style}
            // className="Chen_ipts"
            placeholder={placeholder}
          />
        </label>
      </div>
    ) : (
      <Input
        autoComplete={autoComplete}
        defaultValue={defalutValue}
        onChange={onChange}
        // className="Chen_ipts"
        style={style}
        placeholder={placeholder}
      />
    );
  }
}

const Select = (props) => {
  let { value, children, style } = props;

  const [open, setOpen] = useState(false);

  const onOff = () => {
    setOpen(!open);
  };

  const onChange = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <select
      style={style}
      onBlur={() => {
        setOpen(false);
      }}
      className={!open ? "personSelect select_active" : "personSelect"}
      value={value}
      onClick={onOff}
      onChange={onChange}
    >
      {children.map((i, index) => {
        return (
          <option key={i.value + index} value={i.value} title={i.label}>
            {i.label}
          </option>
        );
      })}
    </select>
  );
};

class Button extends React.Component {
  render() {
    let { text, className, style, type, onClick } = this.props;
    return (
      <button onClick={onClick} type={type} style={style} className={className}>
        {text}
      </button>
    );
  }
}

const SelectAdress = (props) => {
  let {
    onChange,
    disabled = false,
    placeholder,
    defaultValue = "",
    style,
  } = props;

  let arr = [];

  arr[0] = defaultValue.substr(0, 2);

  arr[1] = defaultValue.substr(2, 4);

  arr[2] = defaultValue.substr(6);

  return (
    <Cascader
      disabled={disabled}
      placeholder={placeholder}
      defaultValue={defaultValue ? arr : []}
      style={{ width: "100%", ...style }}
      options={options}
      onChange={onChange}
    />
  );
};

const TabButton = (props) => {
  // const [value, setValue] = useState(props.match.params.id);
  // useEffect(() => {
  //   onChange(value);
  // }, [value, onChange]);

  return (
    <div className="coupon_buttons">
      <div
        className={props.match.params.id * 1 === 1 ? "profile_active" : ""}
        onClick={() => {
          props.history.push("/home/profile/fcodes/1");
        }}
      >
        待使用
      </div>
      <div
        className={props.match.params.id * 1 === 2 ? "profile_active" : ""}
        onClick={() => {
          props.history.push("/home/profile/fcodes/2");
        }}
      >
        已使用
      </div>
      <div
        className={props.match.params.id * 1 === 3 ? "profile_active" : ""}
        onClick={() => {
          props.history.push("/home/profile/fcodes/3");
        }}
      >
        已过期
      </div>
    </div>
  );
};
const CouponTabButton = (props) => {
  return (
    <div className="coupon_buttons">
      <div
        className={props.match.params.id * 1 === 4 ? "profile_active" : ""}
        onClick={() => {
          props.history.push("/home/profile/coupons/4");
        }}
      >
        待领取
      </div>
      <div
        className={props.match.params.id * 1 === 1 ? "profile_active" : ""}
        onClick={() => {
          props.history.push("/home/profile/coupons/1");
        }}
      >
        待使用
      </div>
      <div
        className={props.match.params.id * 1 === 2 ? "profile_active" : ""}
        onClick={() => {
          props.history.push("/home/profile/coupons/2");
        }}
      >
        已使用
      </div>
      <div
        className={props.match.params.id * 1 === 3 ? "profile_active" : ""}
        onClick={() => {
          props.history.push("/home/profile/coupons/3");
        }}
      >
        已过期
      </div>
    </div>
  );
};

const ShowMask = (obj) => {
  var body = document.body;
  var oDiv = document.createElement("div");
  var Mask = document.querySelectorAll(".ant-modal-mask");
  if (obj) {
    body.style.overflow = obj.body;
  }
  if (Mask.length !== 0) {
    Mask.forEach((i) => {
      i.parentNode.removeChild(i);
    });
    body.appendChild(oDiv);
  }
  oDiv.className = "ant-modal-mask";
  Mask.length === 0 && body.appendChild(oDiv);
};

const HiddenMask = (obj) => {
  var mask = document.querySelectorAll(".ant-modal-mask");
  for (let i of mask) {
    i.parentNode.removeChild(i);
  }
  if (obj) {
    document.body.style.overflow = "";
  }
};
export {
  Inputlogin as Input,
  PassWord,
  Select,
  TabButton,
  SelectAdress,
  ShowMask,
  HiddenMask,
  CityParser,
  Button,
  AddressesMobileComponents,
  CouponTabButton,
};
