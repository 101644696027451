import Axios from "axios";

export const LoginApi = async ({ userName, userPwd }) => {
  const result = await Axios.post("/login", { userName, userPwd });
  if (result.data && result.data.code === 0) {
    localStorage.clear();
    localStorage.jwt = result.data.data.token;
    Axios.defaults.headers["Authorization"] =
      "Bearer " + result.data.data.token;
  }
  return result.data;
};

export const quickLogin = async (value) => {
  const result = await Axios.post("/quick-login", value);
  if (result.data && result.data.code === 0) {
    localStorage.clear();
    localStorage.jwt = result.data.data.token;
    Axios.defaults.headers["Authorization"] =
      "Bearer " + result.data.data.token;
  }
  return result.data;
};

export const resetPwd = async (value) => {
  const result = await Axios.post("/resetPwd", value);
  return result.data;
};

export const Join = async (value) => {
  const result = await Axios.post("/join", value);
  if (result.data && result.data.code === 0) {
    localStorage.jwt = result.data.data.token;
    Axios.defaults.headers["Authorization"] =
      "Bearer " + result.data.data.token;
  }
  return result.data;
};

export const isUserFn = async (json) => {
  const result = await Axios.post("/user_exist", json);
  if (result.data && result.data.code === 0) {
    return result.data.data.existing;
  }
  return false;
};
