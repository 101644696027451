import { useState, useEffect, useCallback, useMemo } from "react";
import { PassWordComponent } from "../../router/admin/InputMap";
import { Button, Icon, message } from "antd";
import Axios from "axios";
export const ResetPwd = (props) => {
  const [homeUrl, setUrl] = useState("");

  const [pass, setPwd] = useState({ password: "", PasswordAgain: "" });
  const [passStatus, setPassstatus] = useState({
    test_length: false,
    test_identical: false,
    test_blend: false,
  });
  const dispatch = useCallback((json) => setPwd({ ...pass, ...json }), [pass]);
  let { password, PasswordAgain } = pass;
  const TestValue = useCallback(() => {
    let json = { test_length: false, test_identical: false, test_blend: false };
    if (password) {
      password.length >= 6
        ? (json.test_length = true)
        : (json.test_length = false); //大于6且小于16
      password.match(/(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]/) !== null
        ? (json.test_blend = true)
        : (json.test_blend = false); //密码字母与数字混合 不可出现纯数字 与纯字母
      password === PasswordAgain
        ? (json.test_identical = true)
        : (json.test_identical = false); //相同
    }
    setPassstatus((passStatus) => {
      return { ...passStatus, ...json };
    });
  }, [password, PasswordAgain]);

  useMemo(() => {
    TestValue();
  }, [TestValue]);
  const { test_length, test_blend, test_identical } = passStatus;

  useEffect(() => {
    window.onbeforeunload = () => {
      return "表单规则未保存，是否确定离开此页面？";
    };
    return () => (window.onbeforeunload = null);
  }, []);
  const onSubmit = async () => {
    if (test_length && test_blend && test_identical) {
      //请求重置密码接口
      Axios.post("/merge/resetPwd", { userPwd: pass.password }).then((res) => {
        if (res.data.code !== 0) {
          message.destroy();
          message.error(res.data.message);
          return;
        } else {
          message
            .success("密码重置成功，请重新登录", 3)
            .then(props.history.push("/?path=login"));
        }
      });
    } else {
      if (!password) {
        message.destroy();
        message.error("请输入新密码");
        return;
      }
      if (!PasswordAgain) {
        message.destroy();
        message.error("请再次输入新密码");
        return;
      }
      message.destroy();
      message.error("密码格式不正确");
    }
  };
  useEffect(() => {
    const reload = async () => {
      Axios.get("/background/home").then((res) => {
        if (res.data.code === 0) {
          setUrl(
            res.data.data
              ? res.data.data
              : "https://enroll-resource.oss-cn-shanghai.aliyuncs.com/bg.jpeg"
          );
        }
      });
    };
    reload();
  }, [props]);

  function geTel(tel) {
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    return tel.replace(reg, "$1****$2");
  }
  let user = geTel(localStorage.userName);

  return (
    <div className="home_image" style={{ backgroundImage: `url(${homeUrl})` }}>
      <div className="admin_form">
        <h2 className="admin_from_title">重置密码</h2>
        <div className="login_form_reset">
          <div className="form_first">
            <div className="phoneuser">手机号</div>
            <div>{user}</div>
          </div>
          <div className="form_flex">
            <div className="newpass">新密码</div>
            <PassWordComponent
              autoComplete="new-password"
              onChange={(value) => {
                dispatch({ password: value });
              }}
              placeholder="请输入密码"
            />
          </div>
          <div className="form_flex">
            <div className="confirmpass">确认密码</div>
            <PassWordComponent
              autoComplete="new-password"
              onChange={(value) => {
                dispatch({ PasswordAgain: value });
              }}
              placeholder="请再次输入密码"
            />
          </div>
        </div>
        <div className="reset_pwd">
          <div className="test_password_div">
            <div className={test_length ? "test_password_div_active" : ""}>
              <Icon type="check-circle" style={{ fontSize: "16px" }} />
              <span>长度至少6个字符</span>
            </div>
            <div className={test_blend ? "test_password_div_active" : ""}>
              <Icon type="check-circle" style={{ fontSize: "16px" }} />
              <span>必须包含字母、数字、符号中至少两种</span>
            </div>
            <div className={test_identical ? "test_password_div_active" : ""}>
              <Icon type="check-circle" style={{ fontSize: "16px" }} />
              <span>两次密码一致</span>
            </div>
          </div>
        </div>
        <Button
          onClick={onSubmit}
          style={{
            width: "180px",
            height: "40px",
            background: "rgba(119,158,185,1)",
            color: "#fff",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          完成
        </Button>
      </div>
      {/* <Footer
        style={{
          position: "absolute",
          bottom: "0",
          backgroundColor: "rgba(0,0,0,0.0)",
          color: "#fff",
        }}
      /> */}
    </div>
  );
};
