import { useState, useEffect } from "react";
import { Button } from "antd";
import Footer from "./footer";
import Axios from "axios";
import Login from "./../admin/login";

const App = (props) => {
  const [path, setPath] = useState("");
  // const [user, setToken] = useState({ info: "", token: "" });
  const [homeUrl, setUrl] = useState("");
  useEffect(() => {
    const path2 = props.location.search.substring(1);
    const urlSchema = new URLSearchParams(path2);
    const newPath = urlSchema.get("path");
    if (newPath) {
      setPath(newPath);
      props.history.replace("/");
    }
  }, [props]);

  useEffect(() => {
    const reload = async () => {
      Axios.get("/background/home").then((res) => {
        if (res.data.code === 0) {
          setUrl(
            res.data.data
              ? res.data.data
              : "https://enroll-resource.oss-cn-shanghai.aliyuncs.com/bg.jpeg"
          );
        }
      });
    };
    reload();
    if (localStorage.jwt) {
      props.history.push("/home/mycourses");
    }
  }, [props]);

  return (
    <div className="home_image" style={{ backgroundImage: `url(${homeUrl})` }}>
      <div className="header_conter">
        <div className="header">
          <div className="header_text">博观而约取，厚积而薄发</div>
          <Button
            onClick={() => {
              setPath("login");
            }}
            type="primary"
            className="full_width"
          >
            登录
          </Button>
          <br />
          <button
            onClick={() => {
              setPath("register");
            }}
            className="full_width2"
          >
            注册
          </button>
        </div>
      </div>
      <Login path={path} setPath={setPath} {...props} />
      <Footer
        style={{
          position: "absolute",
          bottom: "0",
          backgroundColor: "rgba(0,0,0,0.0)",
          color: "#fff",
        }}
      />
    </div>
  );
};

export default App;
