import Axios from "axios";
export const getAddress = async () => {
  let res = await Axios.get("/delivery_addresses");

  return res.data;
};

export const getPersonInfo = async () => {
  let res = await Axios.get("/profile");
  return res.data;
};
