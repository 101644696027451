import ReactDom from "react-dom";
import "./index.less";
import "./main.less";
import "./mindex.less";
import "./base.less";
import "./merge.less";

import App from "./App";
import { BrowserRouter } from "react-router-dom";

ReactDom.render(<App />, document.getElementById("react-root"));
