import { createStore } from "redux";

const reducer = (state, action) => {
  state = sessionStorage.userData ? JSON.parse(sessionStorage.userData) : {};

  const { type, payload } = action;

  switch (type) {
    case "SETDATA":
      return { ...state, ...payload };

    default:
      return { ...state };
  }
};

const Store = createStore(reducer);

const { getState, dispatch, subscribe } = Store;

export { getState, dispatch, subscribe };
