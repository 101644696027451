import Axios from "axios";

export const GetRedeemInfo = async (code: string | null) => {
  let res = await Axios.get(`/redeemCodes/${code}`);
  return res.data;
};
export const RedeemUsePost = async (code: string) => {
  let res = await Axios.post(`/redeemCodes/${code}`);
  return res.data;
};
export interface RedeemInfo {
  code: string;
  underlyingGraph?: string;
  backgroundImage?: string;
  remark?: string;
}
