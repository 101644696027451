import React from 'react'

import {Spin} from 'antd'


class  Loading extends React.Component{

    render(){
      return (
        <Spin className='loadings' style={{position:"fixed",top:'50%',left:'50%',transform:'translate(-50%,-50%)'}} />
      )
    }
  }


export default Loading;