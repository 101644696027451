import { goCC } from "m-router/authentication/api";

export const GoCCFn = async (URl) => {
  if (URl) {
    localStorage.setItem("backToUrl", window.location.origin + URl);
  } else {
    localStorage.setItem("backToUrl", window.location.href);
  }

  let res = await goCC({ mode: 1 });
  if (res.code !== 0) {
    message.error(res.message);
  } else {
    window.location.href = res.data.url;
  }
};
