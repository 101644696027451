import moment from "moment";
import { parsePhoneNumber } from "libphonenumber-js";
const price_cig = {
  TWD: "新台币",
  USD: "美元",
  HKD: "港元",
  CNY: "人民币",
};

const country = [
  { label: "港澳台", value: 501 },
  { label: "其他地区", value: 502 },
  { label: "大陆", value: 5 },
  { label: "香港", value: 1 },
  { label: "澳门", value: 3 },
  { label: "台湾", value: 2 },
  { label: "马来西亚", value: 125 },
  { label: "新加坡", value: 217 },
  { label: "阿根廷", value: 241 },
  { label: "尼日利亚", value: 156 },
  { label: "尼加拉瓜", value: 154 },
  { label: "日本", value: 164 },
  { label: "乔治亚", value: 163 },
  { label: "葡萄牙", value: 162 },
  { label: "皮特凯恩", value: 161 },
  { label: "帕劳群岛", value: 160 },
  { label: "诺福克", value: 159 },
  { label: "尼日尔", value: 155 },
  { label: "挪威", value: 158 },
  { label: "纽埃", value: 157 },
  { label: "瑞典", value: 165 },
  { label: "瑞士", value: 166 },
  { label: "萨尔瓦多", value: 167 },
  { label: "圣马力诺", value: 180 },
  { label: "圣卢西亚", value: 179 },
  { label: "圣基茨和尼维斯", value: 178 },
  { label: "圣赫勒拿", value: 177 },
  { label: "圣多美和普林西比", value: 176 },
  { label: "圣诞岛", value: 175 },
  { label: "沙特阿拉伯", value: 174 },
  { label: "塞舌尔", value: 173 },
  { label: "塞浦路斯", value: 172 },
  { label: "塞内加尔", value: 171 },
  { label: "塞拉利昂", value: 170 },
  { label: "塞尔维亚,黑山", value: 169 },
  { label: "萨摩亚", value: 168 },
  { label: "尼泊尔", value: 153 },
  { label: "瑙鲁", value: 152 },
  { label: "美属外岛", value: 136 },
  { label: "美属萨摩亚", value: 135 },
  { label: "美国", value: 134 },
  { label: "毛里塔尼亚", value: 133 },
  { label: "毛里求斯", value: 132 },
  { label: "曼岛", value: 131 },
  { label: "马约特岛", value: 130 },
  { label: "马提尼克", value: 129 },
  { label: "马绍尔群岛", value: 128 },
  { label: "马其顿", value: 127 },
  { label: "马里", value: 126 },
  { label: "马拉维", value: 124 },
  { label: "蒙古", value: 137 },
  { label: "蒙特塞拉特", value: 138 },
  { label: "孟加拉", value: 139 },
  { label: "南乔治亚和南桑德威奇群岛", value: 151 },
  { label: "南极洲", value: 150 },
  { label: "南非", value: 149 },
  { label: "纳米比亚", value: 148 },
  { label: "墨西哥", value: 147 },
  { label: "莫桑比克", value: 146 },
  { label: "摩纳哥", value: 145 },
  { label: "摩洛哥", value: 144 },
  { label: "摩尔多瓦", value: 143 },
  { label: "缅甸", value: 142 },
  { label: "密克罗尼西亚", value: 141 },
  { label: "秘鲁", value: 140 },
  { label: "马耳他", value: 123 },
  { label: "圣皮埃尔和米克隆群岛", value: 181 },
  { label: "斯里兰卡", value: 183 },
  { label: "伊拉克", value: 225 },
  { label: "也门", value: 224 },
  { label: "亚美尼亚", value: 223 },
  { label: "牙买加", value: 222 },
  { label: "叙利亚", value: 221 },
  { label: "匈牙利", value: 220 },
  { label: "新西兰", value: 219 },
  { label: "新喀里多尼亚", value: 218 },
  { label: "希腊", value: 216 },
  { label: "西班牙", value: 215 },
  { label: "乌兹别克斯坦", value: 214 },
  { label: "乌拉圭", value: 213 },
  { label: "伊朗", value: 226 },
  { label: "以色列", value: 227 },
  { label: "意大利", value: 228 },
  { label: "中非共和国", value: 240 },
  { label: "智利", value: 239 },
  { label: "直布罗陀", value: 238 },
  { label: "乍得", value: 237 },
  { label: "泽西岛", value: 236 },
  { label: "赞比亚", value: 235 },
  { label: "越南", value: 234 },
  { label: "约旦", value: 233 },
  { label: "英属印度洋领地", value: 232 },
  { label: "英国", value: 231 },
  { label: "印度尼西亚", value: 230 },
  { label: "印度", value: 229 },
  { label: "乌克兰", value: 212 },
  { label: "乌干达", value: 211 },
  { label: "坦桑尼亚", value: 194 },
  { label: "泰国", value: 193 },
  { label: "塔吉克斯坦", value: 192 },
  { label: "索马里", value: 191 },
  { label: "所罗门群岛", value: 190 },
  { label: "苏里南", value: 189 },
  { label: "苏丹", value: 188 },
  { label: "斯威士兰", value: 187 },
  { label: "斯瓦尔巴和扬马廷", value: 186 },
  { label: "斯洛文尼亚", value: 185 },
  { label: "斯洛伐克", value: 184 },
  { label: "特立尼达和多巴哥", value: 198 },
  { label: "汤加", value: 195 },
  { label: "特克斯和凯克特斯群岛", value: 196 },
  { label: "特里斯坦达昆哈", value: 197 },
  { label: "文莱", value: 210 },
  { label: "委内瑞拉", value: 209 },
  { label: "维尔京群岛，英属", value: 208 },
  { label: "维尔京群岛，美属", value: 207 },
  { label: "危地马拉", value: 206 },
  { label: "瓦努阿图", value: 205 },
  { label: "瓦利斯和福图纳", value: 204 },
  { label: "托克劳", value: 203 },
  { label: "土库曼斯坦", value: 202 },
  { label: "土耳其", value: 201 },
  { label: "图瓦卢", value: 200 },
  { label: "突尼斯", value: 199 },
  { label: "圣文森特和格林纳丁斯", value: 182 },
  { label: "法属圭亚那", value: 61 },
  { label: "布基纳法索", value: 45 },
  { label: "不丹", value: 44 },
  { label: "博茨瓦纳", value: 43 },
  { label: "伯利兹", value: 42 },
  { label: "玻利维亚", value: 41 },
  { label: "波斯尼亚和黑塞哥维那", value: 40 },
  { label: "波兰", value: 39 },
  { label: "波多黎各", value: 38 },
  { label: "冰岛", value: 37 },
  { label: "比利时", value: 36 },
  { label: "贝宁", value: 35 },
  { label: "北马里亚纳群岛", value: 34 },
  { label: "保加利亚", value: 33 },
  { label: "布隆迪", value: 46 },
  { label: "布韦岛", value: 47 },
  { label: "法属波利尼西亚", value: 60 },
  { label: "法罗群岛", value: 59 },
  { label: "法国", value: 58 },
  { label: "厄立特里亚", value: 57 },
  { label: "厄瓜多尔", value: 56 },
  { label: "俄罗斯", value: 55 },
  { label: "多米尼加共和国", value: 54 },
  { label: "多米尼加", value: 53 },
  { label: "多哥", value: 52 },
  { label: "东帝汶", value: 51 },
  { label: "德国", value: 50 },
  { label: "丹麦", value: 49 },
  { label: "朝鲜", value: 48 },
  { label: "百慕大", value: 32 },
  { label: "白俄罗斯", value: 31 },
  { label: "安道尔", value: 15 },
  { label: "爱沙尼亚", value: 14 },
  { label: "爱尔兰", value: 13 },
  { label: "埃塞俄比亚", value: 12 },
  { label: "埃及", value: 11 },
  { label: "阿森松岛", value: 10 },
  { label: "阿塞拜疆", value: 9 },
  { label: "阿曼", value: 8 },
  { label: "阿鲁巴", value: 7 },
  { label: "阿拉伯联合酋长国", value: 6 },
  { label: "阿富汗", value: 4 },
  { label: "安哥拉", value: 16 },
  { label: "安圭拉", value: 17 },
  { label: "巴西", value: 30 },
  { label: "巴拿马", value: 29 },
  { label: "巴林", value: 28 },
  { label: "巴勒斯坦", value: 27 },
  { label: "巴拉圭", value: 26 },
  { label: "巴基斯坦", value: 25 },
  { label: "巴哈马", value: 24 },
  { label: "巴布亚新几内亚", value: 23 },
  { label: "巴巴多斯岛", value: 22 },
  { label: "澳大利亚", value: 21 },
  { label: "奥兰群岛", value: 20 },
  { label: "奥地利", value: 19 },
  { label: "安提瓜岛和巴布达", value: 18 },
  { label: "法属南部领地", value: 62 },
  { label: "马尔代夫", value: 122 },
  { label: "克罗地亚", value: 106 },
  { label: "科威特", value: 105 },
  { label: "科特迪瓦", value: 104 },
  { label: "科摩罗", value: 103 },
  { label: "科科斯群岛", value: 102 },
  { label: "开曼群岛", value: 101 },
  { label: "卡塔尔", value: 100 },
  { label: "喀麦隆", value: 99 },
  { label: "津巴布韦", value: 98 },
  { label: "捷克共和国", value: 97 },
  { label: "柬埔寨", value: 96 },
  { label: "加蓬", value: 95 },
  { label: "加纳", value: 94 },
  { label: "肯尼亚", value: 107 },
  { label: "库克群岛", value: 108 },
  { label: "马达加斯加", value: 121 },
  { label: "罗马尼亚", value: 120 },
  { label: "卢旺达", value: 119 },
  { label: "卢森堡", value: 118 },
  { label: "留尼旺岛", value: 117 },
  { label: "列支敦士登", value: 116 },
  { label: "利比亚", value: 115 },
  { label: "利比里亚", value: 114 },
  { label: "立陶宛", value: 113 },
  { label: "黎巴嫩", value: 112 },
  { label: "老挝", value: 111 },
  { label: "莱索托", value: 110 },
  { label: "拉脱维亚", value: 109 },
  { label: "加拿大", value: 93 },
  { label: "几内亚比绍", value: 92 },
  { label: "格陵兰", value: 76 },
  { label: "格林纳达", value: 75 },
  { label: "格恩西岛", value: 74 },
  { label: "哥斯达黎加", value: 73 },
  { label: "哥伦比亚", value: 72 },
  { label: "刚果民主共和国", value: 71 },
  { label: "刚果", value: 70 },
  { label: "冈比亚", value: 69 },
  { label: "弗兰克群岛", value: 68 },
  { label: "佛得角", value: 67 },
  { label: "芬兰", value: 66 },
  { label: "斐济", value: 65 },
  { label: "菲律宾", value: 64 },
  { label: "古巴", value: 77 },
  { label: "瓜德罗普", value: 78 },
  { label: "几内亚", value: 91 },
  { label: "吉尔吉斯斯坦", value: 90 },
  { label: "吉布提", value: 89 },
  { label: "基里巴斯", value: 88 },
  { label: "洪都拉斯", value: 87 },
  { label: "赫德和麦克唐纳群岛", value: 86 },
  { label: "荷属安地列斯", value: 85 },
  { label: "荷兰", value: 84 },
  { label: "韩国", value: 83 },
  { label: "海地", value: 82 },
  { label: "哈萨克斯坦", value: 81 },
  { label: "圭亚那", value: 80 },
  { label: "关岛", value: 79 },
  { label: "梵蒂冈", value: 63 },
];

var small_enrollStatus = [
  "报名未开始",
  "名额已满",
  "报名暂停",
  "报名结束",
  "立即报名",
  "立即报名",
  "等待付款",
  " 完成付款",
  "报名成功",
  "报名结束",
  "前往认证",
  "无法报名",
  "报名中",
];

var enrollStatusText = [
  "报名未开始",
  "名额已满",
  "报名暂停",
  "报名结束",
  "可报名",
  "可报名",
  "等待付款",
  " 完成付款",
  "报名成功",
  "报名结束",
  "需认证",
  "无法报名",
  "报名中",
];

var PackChildrenStatus = [
  "等待付款",
  "完成付款",
  "报名成功",
  "已取消",
  "报名中",
];
const format = (time, flag) => {
  return typeof time === "string"
    ? time
    : flag === "second"
    ? moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
    : moment(time * 1000).format("YYYY-MM-DD");
};
const ReduceFn = (a, b) => {
  let f = a - b;
  let m = Math.pow(10, 10);
  let num = Math.round(f * m) / m;
  return num;
};

const parseTime = ({
  startTime,
  endTime,
  predStartTime,
  enrollStartTime,
  enrollEndTime,
  predEnrollStartTime,
}) => {
  let enrollText = ""; //报名字段
  let courseText = ""; //开课时间字段
  if (startTime) courseText = format(startTime) + " 上课";
  if (startTime && endTime)
    courseText = format(startTime) + " 至 " + format(endTime);
  if (predStartTime) courseText = predStartTime;
  if (enrollStartTime) enrollText = format(enrollStartTime) + " 开始报名";
  if (enrollStartTime && enrollEndTime)
    enrollText = format(enrollStartTime) + " 至 " + format(enrollEndTime);
  if (predEnrollStartTime) enrollText = predEnrollStartTime;
  return { enrollText, courseText };
};

const InputTest = (str) => {
  return str.replace(/\s/g, "").toUpperCase();
};

const statusText = ["等待付款", "完成付款", "报名成功", "已取消"];

let CountryCode = [
  {
    label: "大陆+86",
    value: "+86",
  },
  {
    label: "香港+852",
    value: "+852",
  },
  {
    label: "澳门+853",
    value: "+853",
  },
  {
    label: "台湾+886",
    value: "+886",
  },
  {
    label: "马来西亚+60",
    value: "+60",
  },
  {
    label: "日本+81",
    value: "+81",
  },
  {
    label: "新加坡+65",
    value: "+65",
  },
  {
    label: "美国/加拿大+1",
    value: "+1",
  },
  {
    label: "澳大利亚+61",
    value: "+61",
  },
  {
    label: "英国+44",
    value: "+44",
  },
];

function phoneCheck(countryCode, user) {
  var telStr;
  switch (countryCode) {
    case "+86":
      telStr =
        /^[1](([3][0-9])|([4][0-9])|([5][0-9])|([6][0-9])|([7][0-9])|([8][0-9])|([9][0-9]))[0-9]{8}$/;

      return telStr.test(user);

    case "+886":
      telStr = /^[09]\d{8}$/;

      return telStr.test(user);

    case "+852":
      telStr = /^[5|6|9]\d{7}$/;

      return telStr.test(user);

    default:
      return /\D/g.test(user) ? false : true;
  }
}

function NewPhoneCheck(countrycode, username) {
  if (username.startsWith("0")) {
    return { newName: username.substr(1), newCountry: countrycode };
  } else {
    return { newName: username, newCountry: countrycode };
  }
}
function passwordLogincheck(username) {
  let phonestr =
    /^[1](([3][0-9])|([4][0-9])|([5][0-9])|([6][0-9])|([7][0-9])|([8][0-9])|([9][0-9]))[0-9]{8}$/g;
  let isnum = /^[0-9]+.?[0-9]*$/g;
  let spliyfirt = username.slice(1, username.length).replace(/\s+/g, ""); //去掉+号，并把所有空格去掉
  let checkflirt = /^[0-9-]*$/g;
  //判断出有+号

  if (username.startsWith("+")) {
    const phoneNumber = parsePhoneNumber(username);
    if (spliyfirt.includes("+")) return "";
    if (!checkflirt.test(spliyfirt)) {
      //是否是纯数字
      return "";
    }
    if (spliyfirt.includes("-")) {
      let finalstring = spliyfirt.split("-");
      if (finalstring.length > 2) {
        return "";
      }

      return {
        country: phoneNumber.countryCallingCode,
        username: finalstring[1],
      };
    }
    if (phoneNumber.country !== "") {
      return {
        username: phoneNumber.formatNational().replace(/[-]|\s+|[()]/g, ""),
        country: phoneNumber.countryCallingCode,
      };
    } else {
      return "";
    }
  } else {
    //判断出邮箱
    if (username.match(/@/g) !== null) {
      if (emailCheck(username)) {
        return { username };
      } else {
        return "";
      }
    }
    //判断出纯数字
    if (isnum.test(username)) {
      if (username.length === 11) {
        if (phonestr.test(username)) {
          return {
            username,
            country: "86",
          };
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
}

//邮箱验证
function emailCheck(e) {
  var reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z]{2,5}$/;
  return reg.test(e);
}

const addFix = (str) => {
  str = str.replace(/[()]/g, "");
  return str.slice(0, -1) + "(" + str.substr(-1) + ")";
};
//大陆身份证校验
function testid(id) {
  // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
  var format =
    /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
  //号码规则校验
  if (!format.test(id)) {
    return { status: 0, msg: "身份证号码不合规" };
  }
  //区位码校验
  //出生年月日校验  前正则限制起始年份为1900;
  var year = parseInt(id.substr(6, 4)), //身份证年
    month = parseInt(id.substr(10, 2)), //身份证月
    date = parseInt(id.substr(12, 2)), //身份证日
    time = Date.parse(month + "-" + date + "-" + year), //身份证日期时间戳date
    now_time = new Date().getTime(), //当前时间戳
    dates = new Date(year, month, 0).getDate(); //身份证当月天数
  if (time > now_time || date > dates) {
    return { status: 0, msg: "出生日期不合规" };
  }
  //校验码判断
  var c = [
    "7",
    "9",
    "10",
    "5",
    "8",
    "4",
    "2",
    "1",
    "6",
    "3",
    "7",
    "9",
    "10",
    "5",
    "8",
    "4",
    "2",
  ]; //系数
  var b = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"]; //校验码对照表
  var id_array = id.split("");
  var sum = 0;
  for (var k = 0; k < 17; k++) {
    sum += parseInt(id_array[k]) * parseInt(c[k]);
  }
  if (id_array[17].toUpperCase() !== b[sum % 11].toUpperCase()) {
    return { status: 0, msg: "身份证校验码不合规" };
  }
  return { status: 1, msg: "校验通过" };
}
// 护照校验
function isPassPortCard(card) {
  return card.length > 20 ? { status: 0 } : { status: 1 };
}
// 港居民身份证校验
function isHKCard(card) {
  if (card[0].match(/[\D]/g) === null) {
    return { status: 0 };
  }
  card = card.replace(/[()]/gi, "");
  var CharCode = card.substr(0, 1).charCodeAt() - 64;
  var TestCode = card.substr(-1);
  var contentArr = card.slice(1, -1).split("");
  contentArr.unshift(CharCode);
  contentArr.reverse();
  var num = 0;

  for (var i = 2; i <= 8; i++) {
    num += contentArr[i - 2] * i;
  }
  if (num % 11 === 0) {
    return TestCode * 1 === 0 ? { status: 1 } : { status: 0 };
  }
  if (TestCode === "A") {
    return num % 11 === 1 ? { status: 1 } : { status: 0 };
  }
  return 11 - (num % 11) === TestCode * 1 ? { status: 1 } : { status: 0 };
}

const isAMCard = (card) => {
  card = card.replace(/[()]/gi, "");
  return /^[1|5|7][0-9]{6}[A-Z0-9]{1}$/.test(card)
    ? { status: 1 }
    : { status: 0 };
};

let TwCarJson = {
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  I: 34,
  J: 18,
  K: 19,
  L: 20,
  M: 21,
  N: 22,
  O: 35,
  P: 23,
  Q: 24,
  R: 25,
  S: 26,
  T: 27,
  U: 28,
  V: 29,
  W: 32,
  X: 30,
  Y: 31,
  Z: 33,
};

function isTWCard(card) {
  //澳门居民

  if (card[0].match(/[A-Z]/g) === null) {
    return { status: 0 };
  }

  var CharCode = TwCarJson[card.substr(0, 1)];

  var content = card.slice(1, -1);

  if (content[0].match(/[1-2]/gi) === null) {
    return { status: 0 };
  }

  var TestCode = card.substr(-1) * 1;

  var revesStr = (CharCode + content).split("").reverse().join("");

  var num = 0;

  for (var i = 1; i <= 9; i++) {
    num += revesStr[i - 1] * i;
  }

  num += revesStr[revesStr.length - 1] * 1;

  if (TestCode === 0) {
    return num % 10 === TestCode ? { status: 1 } : { status: 0 };
  } else {
    return 10 - (num % 10) === TestCode * 1 ? { status: 1 } : { status: 0 };
  }
}

function getByteLen(val) {
  var len = 0;
  for (var i = 0; i < val.length; i++) {
    var a = val.charAt(i);
    if (a.match(/[^\\x00 -\xff]/gi) != null) {
      len += 2;
    } else {
      len += 1;
    }
  }
  return len;
}

export {
  getByteLen,
  testid,
  addFix,
  isPassPortCard,
  isHKCard,
  isAMCard,
  isTWCard,
  TwCarJson,
  format,
  phoneCheck,
  emailCheck,
  InputTest,
  small_enrollStatus,
  enrollStatusText,
  statusText,
  parseTime,
  country,
  price_cig,
  CountryCode,
  ReduceFn,
  PackChildrenStatus,
  passwordLogincheck,
  NewPhoneCheck,
};
