import { Input, message } from "antd";
import { useEffect, useState } from "react";
import {
  GetRedeemInfo,
  RedeemInfo,
  RedeemUsePost,
} from "router/home/redeem/redeemApi";
import Login from "router/admin/login";
import "./redeem.less";
import { Mask } from "antd-mobile";
import store from "lib/redux/store";
export const RedeemPage = (props: any) => {
  const [loginPath, setPath] = useState("");
  const [redeemInfo, setInfo] = useState({
    visible: false,
    courseCode: "",
    giftName: "",
    giftType: 0,
  });
  let pathname = new URLSearchParams(props.location.search);
  let isCode = pathname.get("code") || "";
  const [value, setValue] = useState(isCode);
  const [data, setData] = useState<RedeemInfo>({
    underlyingGraph: "",
    backgroundImage: "",
    code: "",
  });

  let isJwt = localStorage.getItem("jwt");

  useEffect(() => {
    const reload = async () => {
      let res = await GetRedeemInfo(isCode);
      if (res.code !== 0) {
        message.error(res.message);
      } else {
        setData(res.data);
      }
    };

    if (isCode !== null && isCode !== "") {
      reload();
    }
  }, [isCode]);
  const submitRedeem = async () => {
    if (!localStorage.getItem("jwt")) {
      message.error("请先登录");
      setPath("login");
      return;
    }
    if (!sessionStorage.getItem("isclick")) return;
    if (value.replace(/\s+/g, "").length === 0) {
      message.info("您输入的兑换码不存在，请查验后重试", 0.2);
      return;
    } else {
      let res = await RedeemUsePost(value);
      if (res.code !== 0) {
        sessionStorage.removeItem("isclick");
        message.error(res.message);
      } else {
        sessionStorage.removeItem("isclick");
        setInfo((state) => ({
          ...state,
          giftName: res.data.giftName,
          giftType: res.data.giftType,
          courseCode: res.data.courseCode,
          visible: true,
        }));
      }
    }
  };
  useEffect(() => {
    if (isJwt && isCode !== "" && isCode !== null) {
      submitRedeem();
    }
  }, [isJwt, isCode]);

  const onSubmit = async () => {
    sessionStorage.setItem("isclick", "1");
    submitRedeem();
  };
  const redeemType: string[] = ["", "优惠券"];

  useEffect(() => {
    store.subscribe(() => {
      let { tokendelay } = store.getState();
      if (tokendelay) {
        message.error("身份验证已过期，请重新登录");
        setPath("login");
      }
    });
  }, []);
  return isCode ? (
    data.code ? (
      <div>
        <Mask visible={redeemInfo.visible}>
          <div className="maskContentRedeem">
            <div className="redeemTipsTitle">兑换成功</div>
            <div className="redeemTipsContent">
              您已获得
              <span className="couponName">
                {redeemType[redeemInfo.giftType]}
              </span>
              ：<span className="couponName">{redeemInfo.giftName}</span>。
            </div>
            <div
              className="toCourseCode"
              onClick={() => {
                props.history.push(`/home/course/${redeemInfo.courseCode}`);
              }}
            >
              前往课程
            </div>
          </div>
        </Mask>
        <Login path={loginPath} isCourse={true} setPath={setPath} />

        <div
          className="redeemContent"
          style={
            data.backgroundImage !== ""
              ? { backgroundImage: `url(${data.backgroundImage})` }
              : {}
          }
        >
          {data.backgroundImage === "" ? (
            <div className="houbologoBox"></div>
          ) : (
            ""
          )}
          <div>
            <Input
              style={{ textAlign: "center" }}
              value={value}
              defaultValue={value}
              className={
                isCode === null || isCode === "" || data.backgroundImage === ""
                  ? "redeemCodeArea"
                  : "redeemCodeNoImg"
              }
              onChange={(e) => {
                setValue(e.target.value);
              }}
              maxLength={12}
              placeholder="请输入兑换码"
            />
          </div>
          <div
            className={
              data.backgroundImage !== ""
                ? "submitRedeemBtn2"
                : "submitRedeemBtn"
            }
            onClick={onSubmit}
          >
            立即兑换
          </div>
          {data.remark ? <div className="remarkArea">{data.remark}</div> : ""}
        </div>
      </div>
    ) : (
      ""
    )
  ) : (
    <div>
      <Mask visible={redeemInfo.visible}>
        <div className="maskContentRedeem">
          <div className="redeemTipsTitle">兑换成功</div>
          <div className="redeemTipsContent">
            您已获得
            <span className="couponName">
              {redeemType[redeemInfo.giftType]}
            </span>
            ：<span className="couponName">{redeemInfo.giftName}</span>。
          </div>
          <div
            className="toCourseCode"
            onClick={() => {
              props.history.push(`/home/course/${redeemInfo.courseCode}`);
            }}
          >
            前往课程
          </div>
        </div>
      </Mask>
      <Login path={loginPath} isCourse={true} setPath={setPath} />

      <div className="redeemContent">
        {data.backgroundImage === "" ? (
          <div className="houbologoBox"></div>
        ) : (
          ""
        )}
        <div>
          <Input
            style={{ textAlign: "center" }}
            value={value}
            defaultValue={value}
            className={"redeemCodeArea"}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            maxLength={12}
            placeholder="请输入兑换码"
          />
        </div>
        <div className={"submitRedeemBtn"} onClick={onSubmit}>
          立即兑换
        </div>
      </div>
    </div>
  );
};
