import { message } from "antd";
import Axios from "axios";
import store from "lib/redux/store";

import moment from "moment";

// import { message } from "antd";

// import Qs from "qs";
// import { enrollFrontUrl } from "./devloader";
const { enrollFrontUrl } = require("./devloader");
moment.locale("zh-cn");
// let pendingRequests = new Map();
// function generateReqKey(config) {
//   const { method, url, params, data } = config;
//   return [method, url, Qs.stringify(params), Qs.stringify(data)].join("&");
// }

Axios.interceptors.request.use(
  (config) => {
    // const requestKey = generateReqKey(config);
    const token = localStorage.jwt;
    config.baseURL = enrollFrontUrl;
    config.timeout = 300000;
    config.headers["Authorization"] =
      config.headers["Authorization"] || `Bearer ${token}`;
    config.headers["ClientDeviceType"] =
      document.body.clientWidth > 750 ? 1 : 4;
    // if (pendingRequests.has(requestKey)) {
    //   config.cancelToken = new axios.CancelToken((cancel) => {
    //     // cancel 函数的参数会作为 promise 的 error 被捕获
    //     cancel(`重复的请求被主动拦截: ${requestKey}`);
    //   });
    // } else {
    //   pendingRequests.set(requestKey, config);
    //   config.requestKey = requestKey;
    // }
    return config;
  },
  (err) => {
    // pendingRequests.clear();
    message.error("请求时网络错误");
    return Promise.reject(err);
  }
);
Axios.interceptors.response.use(
  (response) => {
    // const requestKey = response.config.requestKey;
    // pendingRequests.delete(requestKey);
    return Promise.resolve(response);
  },
  (error) => {
    // if (Axios.isCancel(error)) {
    //   // message.error("取消重复请求");
    //   return Promise.reject(error);
    // }
    // pendingRequests.clear();
    if (error && error.response) {
      if (error.response.status === 502 || error.response.status === 404) {
        return { data: { code: 3, message: "网络错误" } };
      }
      if (error.response.status === 401) {
        if (!error.response.config.url.includes("redeemCodes")) {
          localStorage.removeItem("jwt");
          window.location.href = "/";
          return { code: 1, message: "登录失败" };
        } else {
          localStorage.removeItem("jwt");
          store.dispatch({ type: "delay", payload: { tokendelay: true } });
        }
      }
    } else {
      // alert("123");
      // message.error("网络错误");
      // return Promise.reject(error);
    }
  }
);

export default Axios;
