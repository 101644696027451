import { useState, useRef, useCallback, useEffect } from "react";

import { PassWord, Button, Input, Select } from "./../../component/pass_input";

import { message, Icon } from "antd";

import { isRegister } from "./../../global_action";

import {
  CountryCode,
  emailCheck,
  passwordLogincheck,
  NewPhoneCheck,
} from "./../../global_cig";

import Axios from "axios";

const UserInputComponent = (props) => {
  let { flag, placeholder, value } = props;
  const onChange = (data) => {
    props.onChange(data);
  };
  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value]);
  const prefixSelector = (
    <Select
      className="select_active"
      style={{
        height: 36,
        width: "35%",
        margin: 0,
        backgroundColor: "rgba(34,34,34,1)",
        color: "#fff",
        borderRadius: 0,
      }}
      onChange={(value) => {
        onChange({ countryCode: value });
      }}
      children={CountryCode}
    />
  );
  return flag ? (
    <Input
      defalutValue={value}
      style={{ width: "63%", marginLeft: "2%", borderRadius: 0 }}
      placeholder={placeholder}
      onChange={({ target: { value } }) =>
        onChange({ userName: value.startsWith("0") ? value.substr(1) : value })
      }
      addonBefore={prefixSelector}
    />
  ) : (
    <Input
      defalutValue={value}
      style={{ borderRadius: 0 }}
      onChange={({ target: { value } }) => onChange({ userName: value })}
      placeholder={placeholder}
    />
  );
};

const PassWordComponent = (props) => {
  const onChange = ({ target: { value } }) => {
    props.onChange(value);
  };

  return <PassWord {...props} onChange={onChange} value={props.initValue} />;
};
var str = true;

const CaptchaComponent = (props) => {
  let [code, setCode] = useState(0);

  let { userName, countryCode, security, action, checked, isPhone, isback } =
    props;

  const SetButton = (index = 59) => {
    setCode(60);
    var timer = setInterval(() => {
      if (str) {
        setCode(index--);
      }
      if (index < 0 || !str) {
        clearInterval(timer);
      }
    }, 1000);
  };

  const BuildCaptcha = useCallback(async () => {
    let value = { action, unique: false };
    if (!userName) {
      if (isPhone) {
        if (!userCheck(userName)) {
          message.destroy();
          message.error("格式错误");
          return;
        }
        message.destroy();
        message.error("请输入手机号");
      }
      return;
    }
    if (isPhone) {
      if (passwordLogincheck(`${countryCode}${userName}`) === "") {
        message.destroy();
        message.error("手机号格式错误");
        return;
      }
      let { newName } = NewPhoneCheck(countryCode, userName);
      let isRegist = await isRegister(`${countryCode}-${newName}`);
      if (!isRegist) {
        message.destroy();
        message.error("此手机号尚未注册");
        return;
      }
    }

    if (!checked && !checkFn({ userName })) {
      message.destroy();
      message.error("格式错误");
      return;
    }

    if (userName.match(/@/g) !== null) {
      value = { ...value, email: userName };
    } else {
      if (action === 2) {
        value = {
          ...value,
          mobileNumber: userName,
          countryCode,
        };
      } else {
        value = {
          ...value,
          mobileNumber: userName,
          countryCode,
        };
      }
    }
    let { email } = value;
    let res;
    if (email) {
      res = await Axios.post("/captcha/email", value);
    } else {
      res = await Axios.post("/captcha/sms", value);
    }
    if (res.data.code !== 0) {
      message.destroy();
      message.error(res.data.message);
      SetButton();
    } else {
      message.success("发送成功");
      SetButton();
    }
  }, [userName, countryCode, action, checked, isPhone]);
  useEffect(() => {
    str = true;
    !security && BuildCaptcha();
    return () => {
      str = false;
    };
  }, [security, BuildCaptcha]);
  return (
    <div style={{ float: "left", width: "40%" }}>
      {code === 0 ? (
        <Button
          onClick={BuildCaptcha}
          text={"获取验证码"}
          type="button"
          className="yzm_bt"
        />
      ) : (
        <Button
          type="button"
          text={`重发验证码(${code})`}
          className="yzm_bt_active"
        />
      )}
    </div>
  );
};

const SliderComponent = (props) => {
  const DivRef = useRef();

  const [Left, setLeft] = useState(0);

  useEffect(() => {
    if (!props.state) {
      setLeft(0);
    }
  }, [props.state]);

  const Move = (event) => {
    if (props.state === true) return;
    var diffX =
      (event.clientX || event.touches[0].pageX) - DivRef.current.offsetLeft;
    let start = false;
    if (event.type === "touchstart") {
      document.ontouchmove = (e) => {
        var moveX = (e.clientX || e.touches[0].pageX) - diffX;
        if (moveX < 0) {
          moveX = 0;
        } else if (moveX > 266 - DivRef.current.offsetWidth) {
          if (!start) {
            setLeft(266 - DivRef.current.offsetWidth);
            start = true;
            props.onChange({ state: true });
            return;
          }
          return;
        }
        !start && setLeft(moveX);
      };
      document.ontouchend = () => {
        document.ontouchmove = null;
        !start && setLeft(0);
      };
    } else {
      event.preventDefault();
      document.onmousemove = (e) => {
        var moveX = (e.clientX || e.touches[0].pageX) - diffX;
        if (moveX < 0) {
          moveX = 0;
        } else if (moveX > 266 - DivRef.current.offsetWidth) {
          if (!start) {
            setLeft(266 - DivRef.current.offsetWidth);
            start = true;
            props.onChange({ state: true });
            return;
          }
          return;
        }
        !start && setLeft(moveX);
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        !start && setLeft(0);
      };
    }
  };

  return !props.state ? (
    <div className="horizontal-slider" style={{ touchAction: "none" }}>
      <div className="horizontal-slider_text">请按住滑块,拖动到最右边</div>
      <div
        className="horizontal-slider_ng"
        style={{ background: "#52c41a", width: Left + "px" }}
      ></div>
      <div
        ref={DivRef}
        onMouseDown={Move}
        onTouchStart={Move}
        style={{ left: Left + "px", cursor: "pointer" }}
        className="thumb"
      >
        {">>"}
      </div>
    </div>
  ) : (
    <div className="horizontal-slider" style={{ background: "#52c41a" }}>
      <div style={{ color: "#fff" }}>验证通过</div>
      <div
        ref={DivRef}
        onMouseDown={Move}
        onTouchStart={Move}
        style={{ left: Left + "px" }}
        className="thumb"
      >
        <Icon type="check" />
      </div>
    </div>
  );
};

export {
  UserInputComponent,
  PassWordComponent,
  CaptchaComponent,
  SliderComponent,
};

const checkFn = (value) => {
  let { userName } = value;

  if (userName.match(/@/g) !== null) {
    return emailCheck(userName);
  }
  return true;
};

function userCheck(user) {
  return user.match(/\D/g) !== null ? false : true;
}
//邮箱验证
