import Axios from 'axios'
export const getPackDetail = async (code) => {
  let res = await Axios.get(`/course_package_codes/${code}`)
  return res.data
}

export const getCourseDetail = async (code) => {
  let res = await Axios.get(`/course_codes/${code}`)
  return res.data
}

