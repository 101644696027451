import { createStore } from "redux";

const reducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case "CHANGESTATE":
      return { ...state, ...payload };
    case "SAVEInfo":
      return { ...state, ...payload };
    case "isReadCoupon":
      return { ...state, ...payload };
    case "delay":
      return { ...state, ...payload };
    case "formValue":
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};

const store = createStore(reducer);

export default store;
