import { message } from "antd";
import { DotLoading } from "antd-mobile";
import { bindCCtalkUser } from "m-router/authentication/api";
import { useEffect } from "react";

export const ResultCCtalkBind = (props) => {
  const params = new URLSearchParams(props.location?.search);
  const code = params.get("code");

  useEffect(() => {
    if (!code) return;
    const reload = async () => {
      let res = await bindCCtalkUser({ code, mode: 1 });
      if (res.code !== 0) {
        message.error(res.message);
        setTimeout(() => {
          window.location.href = localStorage.getItem("backToUrl");
        }, 1000);
      } else {
        message.success("绑定CCtalk成功");
        setTimeout(() => {
          window.location.href = localStorage.getItem("backToUrl");
        }, 2000);
      }
    };
    reload();

    return () => {
      localStorage.removeItem("backToUrl");
    };
  }, [code]);
  return (
    <div className="LoadingStyle">
      Loading
      <DotLoading />
    </div>
  );
};
