import { Input, message } from "antd";
import { useEffect, useState } from "react";
import { GetRedeemInfo, RedeemInfo, RedeemUsePost } from "./redeemApi";
import "./relative.less";
import Login from "../../admin/login";
import { Mask } from "antd-mobile";
import store from "lib/redux/store";
export const RedeemIndex = (props: any) => {
  let pathname = new URLSearchParams(props.location.search);
  let isCode = pathname.get("code") || "";
  let isJwt = localStorage.getItem("jwt");
  const [loginPath, setPath] = useState("");
  const [value, setValue] = useState(isCode);
  const [data, setData] = useState<RedeemInfo>({
    underlyingGraph: "",
    backgroundImage: "",
    code: "",
  });
  const [redeemInfo, setInfo] = useState({
    visible: false,
    giftName: "",
    giftType: 0,
    courseCode: "",
  });
  useEffect(() => {
    const reload = async () => {
      let res = await GetRedeemInfo(isCode);
      if (res.code !== 0) {
        message.error(res.message);
      } else {
        setData(res.data);
      }
    };

    if (isCode !== null && isCode !== "") {
      reload();
    }
  }, [isCode]);

  const submitRedeem = async () => {
    if (!localStorage.getItem("jwt")) {
      message.error("请先登录");
      setPath("login");
      return;
    }
    if (!sessionStorage.getItem("isclick")) return;
    if (value.replace(/\s+/g, "").length === 0) {
      message.info("您输入的兑换码不存在，请查验后重试");
      return;
    } else {
      let res = await RedeemUsePost(value);
      if (res.code !== 0) {
        sessionStorage.removeItem("isclick");
        message.error(res.message);
      } else {
        sessionStorage.removeItem("isclick");
        setInfo((state) => ({
          ...state,
          visible: true,
          giftName: res.data.giftName,
          courseCode: res.data.courseCode,
          giftType: res.data.giftType,
        }));
      }
    }
  };
  useEffect(() => {
    if (isJwt && isCode !== "" && isCode !== null) {
      submitRedeem();
    }
  }, [isJwt, isCode]);

  useEffect(() => {
    store.subscribe(() => {
      let { tokendelay } = store.getState();
      if (tokendelay) {
        message.error("身份验证已过期，请重新登录");
        setPath("login");
      }
    });
  }, []);

  const onSubmit = async () => {
    sessionStorage.setItem("isclick", "1");
    submitRedeem();
  };
  const redeemType: string[] = ["", "优惠券"];
  return isCode ? (
    data.code ? (
      <div
        className="redeemBox"
        style={{
          backgroundImage: `url(${
            data.underlyingGraph === "" ? "" : data.underlyingGraph
          })`,
        }}
      >
        <Mask visible={redeemInfo.visible}>
          <div className="maskContentRedeempc">
            <div className="redeemTipsTitlepc">兑换成功</div>
            <div className="redeemTipsContentpc">
              您已获得
              <span className="couponName">
                {redeemType[redeemInfo.giftType]}
              </span>
              ：<span className="couponName">{redeemInfo.giftName}</span>。
            </div>
            <div
              className="toCourseCodepc"
              onClick={() => {
                props.history.push(`/home/course/${redeemInfo.courseCode}`);
              }}
            >
              前往课程
            </div>
          </div>
        </Mask>
        <Login path={loginPath} isCourse={true} setPath={setPath} />
        <div
          className="redeemCenter"
          style={{
            backgroundImage: `url(${
              data.backgroundImage !== "" ? data.backgroundImage : ""
            })`,
          }}
        >
          {isCode === null || isCode === "" || data.underlyingGraph === "" ? (
            <div className="houbologo">
              <img src={require("../../../public/houbo.png")} />
            </div>
          ) : (
            ""
          )}

          <div className="redeemCenter_style">
            <div className="redeemInput">
              <div>
                <Input
                  style={{ textAlign: "center" }}
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  defaultValue={value}
                  className={
                    isCode === null ||
                    isCode === "" ||
                    data.backgroundImage === ""
                      ? "redeemInputBoxCode"
                      : "redeemInputBox"
                  }
                  maxLength={12}
                  placeholder="请输入兑换码"
                />
              </div>
              <div
                className={
                  data.underlyingGraph !== "" ? "submitBtnCode" : "submitBtn"
                }
                onClick={onSubmit}
              >
                立即兑换
              </div>
              {data.remark ? (
                <div className="remarkBox">{data.remark}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    )
  ) : (
    <div className="redeemBox">
      <Mask visible={redeemInfo.visible}>
        <div className="maskContentRedeempc">
          <div className="redeemTipsTitlepc">兑换成功</div>
          <div className="redeemTipsContentpc">
            您已获得
            <span className="couponName">
              {redeemType[redeemInfo.giftType]}
            </span>
            ：<span className="couponName">{redeemInfo.giftName}</span>。
          </div>
          <div
            className="toCourseCodepc"
            onClick={() => {
              props.history.push(`/home/course/${redeemInfo.courseCode}`);
            }}
          >
            前往课程
          </div>
        </div>
      </Mask>
      <Login path={loginPath} isCourse={true} setPath={setPath} />
      <div className="redeemCenter">
        <div className="houbologo">
          <img src={require("../../../public/houbo.png")} />
        </div>
        <div className="leadIcon">
          <img src={require("../../../public/leadIcon.png")} />
        </div>
        <div className="redeemCenter_style">
          <div className="redeemInput">
            <div>
              <Input
                style={{ textAlign: "center" }}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                defaultValue={value}
                className={
                  isCode === null ||
                  isCode === "" ||
                  data.backgroundImage === ""
                    ? "redeemInputBoxCode"
                    : "redeemInputBox"
                }
                maxLength={12}
                placeholder="请输入兑换码"
              />
            </div>
            <div className={"submitBtn"} onClick={onSubmit}>
              立即兑换
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
