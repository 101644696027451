import Axios from "axios";
const { enrollFrontUrl } = require("../../devloader");
export const getStage = async () => {
  let res = await Axios.get("/certification_stage");
  return res.data;
};

export const bindCCtalkUser = async (code, mode) => {
  let res = await Axios.get(`/oauth/cctalk`, { params: code, mode });
  return res.data;
};

export const getContract = async () => {
  let res = await Axios.get("/contract");
  return res.data;
};

export const rebindCCtalk = async () => {
  let res = await Axios.post("/rebind_cctalk");
  return res.data;
};

export const NextStep = async () => {
  let res = await Axios.post("/into_next_stage");
  return res.data;
};

export const goCC = async (mode) => {
  let res = await Axios.get("/oauth/page/cctalk", { params: mode });
  return res.data;
};

export const submitAuthentication = async (json) => {
  let res = await Axios.post("/real_name_authentication", json, {
    headers: { ClientDeviceType: 4 },
  });
  return res.data;
};

export const uploadFIle = async (formdata) => {
  // let res = await Axios.post("/id_photos", data);
  // return res.data;
  return fetch(`${enrollFrontUrl}/id_photos`, {
    method: "post",
    body: formdata,
    headers: {
      Authorization: `Bearer ${localStorage.jwt}`,
    },
  }).then((res) => res.json());
};

export const goBackStep = async () => {
  let res = await Axios.post("/return_to_real_name_authentication");
  return res.data;
};

export const uploadFiles = async (formData) => {
  let res = await Axios.post("/certification_photos", formData);
  return res.data;
};

export const verifySms = async (value) => {
  let res = await Axios.post("/verify/captcha", value);
  return res.data;
};
export const getNewStatus = async () => {
  let res = await Axios.post("/reset_audit_status");
  return res.data;
};
