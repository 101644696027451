import { useEffect, Suspense } from "react";

import Loading from "./../../loading";

import Header from "./header";

import { Route } from "react-router-dom";

import My_Class from "./class";

import { dispatch } from "./Store";

import Course_detail from "./course/index";

import Footer from "./../index/footer";

import Loadable from "@loadable/component";

import { getUserinformation } from "./../../global_action";

const Mycollect = Loadable(() => import("./class/mycollect"));

const Help = Loadable(() => import("./help/index"));

const Course_Order = Loadable(() => import("./course/order"));

const Enrolments = Loadable(() => import("./course/enrolments"));

const Search_course = Loadable(() => import("./class/search_course"));

const Profile = Loadable(() => import("./profile/index"));

const PaySuccess = Loadable(() => import("./pay_success"));

const ExplicitEnrolments = Loadable(() => import("./course/explicitOrder"));

const CourseWork = Loadable(() => import("./course-work/course-work"));

const StudentWorkStatistic = Loadable(() =>
  import("./course-work/student-statistic")
);

const CoursePack_detail = Loadable(() => import("./coursepack/index"));

const PacksEnrolments = Loadable(() => import("./coursepack/packenrollments"));

const App = (props) => {
  const setUserInfo = async () => {
    let res = await getUserinformation();
    dispatch({ type: "SETDATA", payload: { ...res } });
  };

  useEffect(() => {
    setUserInfo();
  }, []);

  return (
    <div className="_home">
      <Header {...props} />
      <Suspense fallback={Loading}>
        <div className="_home_body">
          <Route
            path="/home/coursepack_enrolments"
            component={PacksEnrolments}
          />
          <Route path="/home/coursepack/:id" component={CoursePack_detail} />
          <Route path="/home/mycollect" component={Mycollect} />
          <Route path="/home/pay_success" component={PaySuccess} />
          <Route path="/home/mycourses" component={My_Class} />
          <Route
            path={["/home/course/:id", "/home/course"]}
            component={Course_detail}
          />
          <Route
            path="/home/profile"
            render={(props) => <Profile {...props} reload_fn={setUserInfo} />}
          />
          <Route path="/home/enrolments" component={Enrolments} />
          <Route
            path="/home/explicitEnrolments"
            component={ExplicitEnrolments}
          />
          <Route
            path={["/home/course_order/:id", "/home/course_order"]}
            component={Course_Order}
          />
          <Route
            path={[
              "/home/assistantswork/:id/:type/:syllabusId/:userId",
              "/home/coursework/:id/:type/:syllabusId",
              "/home/coursework/:id",
              "/home/coursework",
              "/home/assistantswork/:id/:type/:syllabusId",
              "/home/assistantswork/:id",
              "/home/assistantswork",
            ]}
            component={CourseWork}
          />
          <Route path="/home/search_course" component={Search_course} />
          <Route path="/home/help" component={Help} />
          <Route
            path="/home/statistic/:courseCode"
            component={StudentWorkStatistic}
          />
        </div>
      </Suspense>
      <Footer style={{ color: "#666666" }} />
    </div>
  );
};

export default App;
