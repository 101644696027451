import React from "react";

const Flooter = (props) => {
  let { style } = props;
  return (
    <div className="footer" style={style}>
      <div className="footer_content">
        <a
          href="http://beian.miit.gov.cn/"
          target="_blank"
          style={{ color: "rgb(102, 102, 102)" }}
        >
          沪ICP备16036491号-2
        </a>{" "}
        Copyright 2019 Houbo. All Rights Reserved. 厚薄公司 版权所有
      </div>
    </div>
  );
};

export default Flooter;
