import React, { useEffect } from "react";

import { Route } from "react-router-dom";

import { dispatch } from "./../router/home/Store";

import Loadable from "@loadable/component";

import { getUserinformation } from "./../global_action";

const Help = Loadable(() => import("./../router/home/help/index"));

const Course_Order = Loadable(() => import("./../router/home/course/order"));

const Enrolments = Loadable(() => import("./order/enrolments"));

const PacksEnrolments = Loadable(() => import("./order/packenrollments"));

const Search_course = Loadable(() => import("./courseList/search"));

const Profile = Loadable(() => import("./profile/profile"));

const PaySuccess = Loadable(() => import("./../router/home/pay_success"));

const CourseDetail = Loadable(() => import("./course/course"));

const CoursePack_detail = Loadable(() => import("./coursepack/coursepack"));

const MycourseList = Loadable(() => import("./courseList/courseList"));

const Coupon = Loadable(() => import("./profile/coupons"));

const Contart = Loadable(() => import("./about/contact"));

const Setting = Loadable(() => import("./setting/setting"));

const Userprofile = Loadable(() => import("./setting/userProfile"));

const EditProfile = Loadable(() => import("./setting/editProfile"));

const Security = Loadable(() => import("./setting/security/security.js"));

const VerifyComponent = Loadable(() =>
  import("./setting/security/verification_user")
);

const BandingComponent = Loadable(() =>
  import("./setting/security/banding_user")
);

const Addresses = Loadable(() => import("./setting/addresses.tsx"));

const TodoAddresses = Loadable(() => import("./setting/editAddresses.tsx"));

const Myorder = Loadable(() => import("./profile/myOrder.tsx"));

const ExplicitEnrolments = Loadable(() =>
  import("./profile/explicitEnrolments.tsx")
);

const Authenttcation = Loadable(() => import("./realNameAuthoration/index"));
const ReScanIndex = Loadable(() => import("./realNameAuthoration/ReScanPage"));
const courseContract = Loadable(() => import("./order/courseContract"));
const MiddleFaceRequest = Loadable(() =>
  import("./faceAuthentic/faceMiddleRequest")
);
const FaceReault = Loadable(() => import("./faceAuthentic/faceResult"));
const FaceUniappReault = Loadable(() => import("./faceAuthentic/uniappResult"));
const App = (props) => {
  const setUserInfo = async () => {
    let res = await getUserinformation();
    dispatch({ type: "SETDATA", payload: { ...res } });
  };

  useEffect(() => {
    if (window.location.href) {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    if (props.location.pathname.includes("faceRecognitionResult_mp")) return;
    setUserInfo();
  }, []);

  return (
    <div className="mHoubo_home">
      <div className="mHoubo_body">
        <Route path="/home/contact" component={Contart} />
        <Route path="/home/pay_success" component={PaySuccess} />
        <Route
          path={["/home/collect", "/home/mycourses"]}
          component={MycourseList}
        />
        <Route path={"/home/courseContract"} component={courseContract} />
        <Route path="/home/course/:id" component={CourseDetail} />
        <Route path="/home/coursepack/:id" component={CoursePack_detail} />
        <Route
          path="/home/profile"
          exact
          render={(props) => <Profile {...props} reload_fn={setUserInfo} />}
        />
        <Route path="/home/enrolments" component={Enrolments} />
        <Route path="/home/coursepack_enrolments" component={PacksEnrolments} />
        <Route
          path={["/home/course_order/:id", "/home/course_order"]}
          component={Course_Order}
        />
        <Route path="/home/setting" component={Setting} />
        <Route path="/home/searchCourse" component={Search_course} />
        <Route path="/home/help" component={Help} />
        <Route path="/home/userprofile" component={Userprofile} />
        <Route path={["/home/coupons", "/home/fcodes"]} component={Coupon} />
        <Route path={"/home/editProfile/:pathname"} component={EditProfile} />
        <Route path="/home/security" component={Security} />
        <Route path="/home/verify_user/:name" component={VerifyComponent} />
        <Route path="/home/Banding_user/:name" component={BandingComponent} />
        <Route path="/home/addresses" component={Addresses} />
        <Route path="/home/todoaddresses/:name" component={TodoAddresses} />
        <Route path="/home/profile/authorith" component={Authenttcation} />
        <Route path="/home/profile/authorithScan" component={ReScanIndex} />
        <Route path="/home/myorder" component={Myorder} />
        <Route path="/home/faceRecognition" component={MiddleFaceRequest} />
        <Route path="/home/faceRecognitionResult" component={FaceReault} />
        <Route
          path="/home/faceRecognitionResult_mp"
          component={FaceUniappReault}
        />
        <Route
          path="/home/explicitEnrolments/:name"
          component={ExplicitEnrolments}
        />
      </div>
    </div>
  );
};

export default App;
