import Axios from 'axios';

export const getCourse = async code=>{
    let res = await Axios.get(`/course_codes/${code}`);
    return res.data;
}

export const getJWT = async (key)=>{
    let res = await Axios.get('/key_token',{params:{key}});
    return res.data;
}