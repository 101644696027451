import React, { useState, useEffect } from "react";

import { message } from "antd";

import { statusText } from "./../../../global_cig";

import { Link } from "react-router-dom";

import { getCourseList } from "./courseListApi";

const App = (props) => {
  const [course, setCourse] = useState([]);

  const initFn = async () => {
    let res = await getCourseList();
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      setCourse(res.data.list);
    }
  };
  useEffect(() => {
    initFn();
  }, []);

  return (
    <div className="content">
      <div className="my-course-box">
        <div
          onClick={() => props.history.push(`/home/search_course`)}
          className="myclass_myCourse left"
        >
          <div className="myCourse_icon"></div>{" "}
        </div>
        {course.map((i, index) => {
          let className = "myclass_myCourse_list";
          return (
            <div key={i.code + index} className={className}>
              <div className="myCourse_header">
                {i.status !== 2 && <div className="myCourseInfoIcon"> </div>}
                <span className="myCourseInfoText">{statusText[i.status]}</span>
              </div>
              <Link
                key={i.smallPicture}
                to={
                  i.type === 1
                    ? `/home/course/${i.code}`
                    : `/home/coursepack/${i.code}`
                }
                style={{ overflow: "hidden", display: "block" }}
              >
                <img
                  style={{ objectFit: "cover" }}
                  src={i.smallPicture}
                  alt="加载中"
                />
                <div className="course_Mask2"></div>
                <div className="myCourse_footer">
                  <h4>
                    {i.type === 2 ? "(课程包)" : ""}
                    {i.seriesName} - {i.courseName}{" "}
                  </h4>

                  {i.type === 1 ? (
                    <span style={{ color: "#fff" }}>
                      上课时间：{i.startTime}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default App;
